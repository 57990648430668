import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Tabs, { TabHeader, NavItem, TabContent, TabPane } from "@ui/tabs/layout-one";
import { SectionWrap } from "./style";
import imageOne from "@data/images/HydroPhonics-cropped.png";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from "react-accessible-accordion";
import AccordionWrap from "@ui/accordion";
import SectionTitle from "@ui/section-title";
import PopupVideo from "@ui/popup-video/layout-two";


const textStyle = {
    color:"#6d6fb0",
    display: "unset",
  }
const TutorialSection = ({ accordionStyle }) => {
	const getTrainData = useStaticQuery(graphql`
        query GET_TRAIN_SECTION_four_DATA {
            intro: researchTrainingJson(id: {eq: "training-section-one"}) {
                title
            }
			ganblr: researchTrainingJson(id: {eq: "tutorials"}) {
                items {
                    id
                    question
                    answer
					link
                }
            }
            ganblr_video: researchTrainingJson(id: {eq: "ganblr-video"}) {
                ganblr_image {
                    childImageSharp {
						gatsbyImageData(
							layout: CONSTRAINED
							width: 570
							height: 350
							placeholder: TRACED_SVG
							formats: WEBP
							quality: 100
						)
                    }
                }
                ganblr_video_link
            }
            trainLeft: researchTrainingJson(id: {eq: "section-1"}) {
                itemsLeft {
                    id
                    question
                    answer
					link
                }
            }
			trainRight: researchTrainingJson(id: {eq: "section-1"}) {
                itemsRight {
                    id
                    question
                    answer
					link
                }
            }
			train: researchTrainingJson(id: {eq: "section-2"}) {
                items {
                    id
                    question
                    answer
					link
                }
            }
            video: researchTrainingJson(id: {eq: "uc-video"}) {
                image {
                    childImageSharp {
						gatsbyImageData(
							layout: CONSTRAINED
							width: 570
							height: 350
							placeholder: TRACED_SVG
							formats: WEBP
							quality: 100
						)
                    }
                }
                video_link
            }
			train1: researchTrainingJson(id: {eq: "section-3"}) {
                items {
                    id
                    question
                    answer
					link
                }
            }
            video1: researchTrainingJson(id: {eq: "pc-video"}) {
                image1 {
                    childImageSharp {
						gatsbyImageData(
							layout: CONSTRAINED
							width: 570
							height: 350
							placeholder: TRACED_SVG
							formats: WEBP
							quality: 100
						)
                    }
                }
                video_link1
            }
            
        }
		
    `)
	const { trainLeft: { itemsLeft: trainsLeft }, trainRight: { itemsRight: trainsRight }, train: { items: trains }, video: { image, video_link }, train1: { items: trains1 }, video1: { image1, video_link1 }, ganblr: { items: ganblrs }, ganblr_video: { ganblr_image, ganblr_video_link }} = getTrainData
	return (
		<SectionWrap>
			<Container>
				<Row>
					<Col lg={12}>
						<Tabs defaultActiveKey="our-mission-tab">
							<TabHeader>
								<NavItem eventKey="our-mission-tab">Tutorials</NavItem>
								<NavItem eventKey="our-services-tab">PG Courses</NavItem>
								<NavItem eventKey="partners-tab">UG Courses</NavItem>
								<NavItem eventKey="our-awards-tab">FLIP Training</NavItem>
							</TabHeader>
							<TabContent pt="50px">
								<TabPane eventKey="our-mission-tab">
								<Row>
									<Col lg={6}>
										<AccordionWrap {...accordionStyle} layout="two">
											<Accordion allowZeroExpanded preExpanded={[0]}>
												{
													ganblrs.map((el, index) => {
														return (
															<AccordionItem id={el.id} key={index}>
																<AccordionItemHeading>
																	<AccordionItemButton>
																		{el.question}
																	</AccordionItemButton>
																</AccordionItemHeading>
																<AccordionItemPanel>
																	<p>{el.answer}</p>
																	<a display="inline" href={el.link} rel="noopener noreferrer" style={textStyle}>Learn More</a>
																</AccordionItemPanel>
															</AccordionItem>
														)
													})
												}
											</Accordion>
										</AccordionWrap>
									</Col>
									<Col lg={6}>
										<PopupVideo
											image={ganblr_image}
											video_link={ganblr_video_link}
										/>
									</Col>
								</Row>
								</TabPane>
								<TabPane eventKey="our-services-tab">
								<Row>
									<Col lg={6}>
										<AccordionWrap {...accordionStyle} layout="two">
											<Accordion allowZeroExpanded preExpanded={[0]}>
												{
													trains1.map((el, index) => {
														return (
															<AccordionItem id={el.id} key={index}>
																<AccordionItemHeading>
																	<AccordionItemButton>
																		{el.question}
																	</AccordionItemButton>
																</AccordionItemHeading>
																<AccordionItemPanel>
																	<p>{el.answer}</p>
																	<a display="inline" href={el.link} target="_blank" rel="noopener noreferrer" style={textStyle}>Learn More</a>
																</AccordionItemPanel>
															</AccordionItem>
														)
													})
												}
											</Accordion>
										</AccordionWrap>
									</Col>
									<Col lg={6}>
										<PopupVideo
											image={image1}
											video_link={video_link1}
										/>
									</Col>
								</Row>
								</TabPane>
								<TabPane eventKey="partners-tab">
								<Row>
									<Col lg={6}>
										<AccordionWrap {...accordionStyle} layout="two">
											<Accordion allowZeroExpanded preExpanded={[0]}>
												{
													trains.map((el, index) => {
														return (
															<AccordionItem id={el.id} key={index}>
																<AccordionItemHeading>
																	<AccordionItemButton>
																		{el.question}
																	</AccordionItemButton>
																</AccordionItemHeading>
																<AccordionItemPanel>
																	<p>{el.answer}</p>
																	<a display="inline" href={el.link} target="_blank" rel="noopener noreferrer" style={textStyle}>Learn More</a>
																</AccordionItemPanel>
															</AccordionItem>
														)
													})
												}
											</Accordion>
										</AccordionWrap>
									</Col>
									<Col lg={6}>
										<PopupVideo
											image={image}
											video_link={video_link}
										/>
									</Col>
								</Row>
								</TabPane>
								<TabPane eventKey="our-awards-tab">
								<Row>
									<Col lg={6}>
										<AccordionWrap {...accordionStyle} layout="two">
											<Accordion allowZeroExpanded preExpanded={[0]}>
												{
													trainsLeft.map((el, index) => {
														return (
															<AccordionItem id={el.id} key={index}>
																<AccordionItemHeading>
																	<AccordionItemButton>
																		{el.question}
																	</AccordionItemButton>
																</AccordionItemHeading>
																<AccordionItemPanel>
																	<p>{el.answer}</p>
																	<a display="inline" href={el.link} target="_blank" rel="noopener noreferrer" style={textStyle}>Learn More</a>
																</AccordionItemPanel>
															</AccordionItem>
														)
													})
												}
											</Accordion>
										</AccordionWrap>
									</Col>
									<Col lg={6}>
										<AccordionWrap {...accordionStyle} layout="two">
											<Accordion allowZeroExpanded preExpanded={[0]}>
												{
													trainsRight.map((el, index) => {
														return (
															<AccordionItem id={el.id} key={index}>
																<AccordionItemHeading>
																	<AccordionItemButton>
																		{el.question}
																	</AccordionItemButton>
																</AccordionItemHeading>
																<AccordionItemPanel>
																	<p>{el.answer}</p>
																	<a display="inline" href={el.link} target="_blank" rel="noopener noreferrer" style={textStyle}>Learn More</a>
																</AccordionItemPanel>
															</AccordionItem>
														)
													})
												}
											</Accordion>
										</AccordionWrap>
									</Col>
								</Row>
								</TabPane>
							</TabContent>
						</Tabs>
					</Col>
				</Row>
			</Container>
		</SectionWrap>
	)
}

TutorialSection.defaultProps = {
	accordionStyle: {
		responsive: {
			medium: {
				mb: '50px'
			}
		}
	}
}

export default TutorialSection
